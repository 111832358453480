<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="pl-4 d-flex align-center justify-space-between">
                <h1 class="text-h4" style="flex-grow: 1">Aktuelt</h1>
                <v-btn color="primary" to="/aktuelt/ny" v-if="this.hasRole('ANSATT')">
                    Ny artikkel
                    <v-icon right>mdi-plus</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="12" v-if="artikler.length > 0">
                <articles-list :artikler="artikler" :cols="3"> </articles-list>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ArticlesList from '@/components/ArticlesList.vue';
import moment from 'moment';

export default {
    name: 'ArticlesPage',
    components: {
        ArticlesList,
    },
    data() {
        return {
            artikler: [],
        };
    },
    computed: {
        ...mapGetters('api', ['user', 'hasRole', 'maalgrupper']),
    },
    created: async function () {
        await this.update();
    },

    methods: {
        ...mapActions('api', ['request', 'formatArticle']),

        /**
         * update
         */
        update: async function () {
            const maalgrupper = this.maalgrupper;
            const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            const artikler = [];

            // artikler
            if (this.hasRole('ANSATT')) {
                let response = await this.request({
                    method: 'get',
                    url: '/artikkel',
                    //data: maalgrupper,
                });

                if (response && Array.isArray(response)) {
                    for (let artikkel of response) {
                        artikkel = await this.formatArticle(artikkel);
                        artikler.push(artikkel);
                    }
                }
            } else {
                // artikler
                let response = await this.request({
                    method: 'post',
                    url: '/artikkel/sok',
                    data: {
                        publisert: today.format('DD.MM.YYYY HH:mm'),
                        maalgrupper: maalgrupper,
                    },
                });

                if (response && Array.isArray(response)) {
                    for (let artikkel of response) {
                        artikkel = await this.formatArticle(artikkel);
                        artikler.push(artikkel);
                    }
                }
            }

            this.artikler = artikler;
        },
    },
};
</script>
